import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BodyFeatureA from "./BodyFeatureA";
import "../Styles/BodyA.css";

const BodyA = ({ isMobile }) => {
	return (
		<div className="full-width-wrapper-grey">
			<Container fluid className="BodyA">
				<Row>
					<Col xs={{ offset: 1, span: 10 }} sm={10}>
						<h3>Services at Mayflower Group</h3>
					</Col>
				</Row>
				<Row className="FeaturesContentRow">
					<Col xs={{ offset: 1, span: 10 }} sm={{ offset: 1, span: 5 }}>
						<BodyFeatureA
							title={"Residential Strata Buildings"}
							body={
								"At Mayflower Group we understand that regular maintenance and cleaning is essential to a premium living experience. That is why we offer  maintenance and cleaning services for residential strata buildings."
							}
							buttonText={"Our Services"}
							linkHref={"/services"}
						></BodyFeatureA>
					</Col>
					<Col xs={{ offset: 1, span: 10 }} sm={{ offset: 1, span: 5 }}>
						<BodyFeatureA
							title={"Commercial Cleaning Services"}
							body={
								"With decades of proven experience cleaning commercial facilities, we are cleaning specialists that ensure  your facility is always clean and presentable for customers."
							}
							buttonText={"Our Services"}
							linkHref={"/services"}
						></BodyFeatureA>
					</Col>
				</Row>
				<Row className="FeaturesContentRow">
					<Col
						xs={{ offset: 1, span: 10 }}
						sm={{ offset: 1, span: 5 }}
						style={{ paddingBottom: "0", paddingTop: "50px" }}
					>
						<BodyFeatureA
							title={"24/7/365 Emergency Maintenance and Cleaning Services"}
							body={
								"Our services are available 24 hours a day, 7 days a week, and 365 days a year. We have emergency response teams and a reserve pool to support and attend to any of our client’s need."
							}
							buttonText={"Our Services"}
							linkHref={"/services"}
						></BodyFeatureA>
					</Col>
					<Col
						xs={{ offset: 1, span: 10 }}
						sm={{ offset: 1, span: 5 }}
						style={{ paddingBottom: "0", paddingTop: "50px" }}
					>
						<BodyFeatureA
							title={"Go Green Products and Trained Staff"}
							body={
								"At Mayflower Group we strive to protect our environment using environmentally friendly cleaning products and equipment.  All of our janitors and caretakers have Green Certification and WHMIS course certification."
							}
							buttonText={"Our Services"}
							linkHref={"/services"}
						></BodyFeatureA>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default BodyA;
