import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "../Styles/About.css";

const About = ({isMobile}) => {
	return (
		<div className="about">
			<Container fluid>
				<Row className="d-flex flex-column justify-content-center align-items-center">
					<Col
						className="d-flex flex-column justify-content-center align-items-center text-center"
						xs={{ offset: 0, span: 10 }}
					>
						<h3 style={{ marginBottom: "0" }}>About Us</h3>
						<hr style={{ width: "150px", marginTop: "0" }} />
						<h5
							style={{
								textAlign: "center",
								fontWeight: "400",
								fontSize: "1rem",
								fontStyle: "italic",
							}}
						>
							“our mission is to provide excellent and reliable services to our
							valuable clients”
						</h5>
						<h5>
							Professional Building Maintenance and Cleaning Services Since 1996
						</h5>
						<p>
							At Mayflower Group we provide professional building maintenance
							and cleaning services. We have been providing dependable and
							reliable service in the Lower Mainland since 1996. With over 25
							years of experience, we are confident we can handle any of our
							client’s concerns while providing an affordable rate. We are a
							British Columbia owned and operated company. Our core values are
							“Care, Respect, and Responsibility”. We care for and respect our
							staff and our clients’ needs. We take responsibility to protect
							the environment by using environmentally friendly cleaning
							products.
						</p>
					</Col>
				</Row>
				<Row
					className="d-flex flex-column justify-content-center align-items-center text-center"
					style={{ marginBottom: "5px" }}
				>
					<Col className="d-flex flex-column justify-content-center align-items-center text-center">
						<h3>Our Work in 2021</h3>
						<hr style={{ width: "250px", marginTop: "0" }} />
					</Col>
				</Row>
				<Row
					className="d-flex flex-row justify-content-center align-items-center text-center"
					style={{
						marginTop: "10px",
						width: "80%",
						marginRight: "auto",
						marginLeft: "auto",
					}}
				>
					<Col
						xs={{ offset: 0, span: 12 }}
						sm={{ offset: 0, span: 6 }}
					>
						{" "}
						<img
							style={{ objectFit: "contain", height: "100%" }}
							className="imageGallery"
							src="./Images/2021_1.jpg"
							alt="2021 (1)"
						/>
					</Col>

					<Col
						xs={{ offset: 0, span: 12 }}
						sm={{ offset: 0, span: 6 }}
					>
						<img
							className="imageGallery"
							style={{ objectFit: "contain", height: "100%" }}
							src="./Images/2021_5.jpg"
							alt="2021 (2)"
						/>
					</Col>
					<Col
						xs={{ offset: 0, span: 12 }}
						sm={{ offset: 0, span: 6 }}
					>
						{" "}
						<img
							style={{ objectFit: "contain", height: "100%" }}
							className="imageGallery"
							src="./Images/2021_3.jpg"
							alt="2021 (3)"
						/>
					</Col>

					<Col
						xs={{ offset: 0, span: 12 }}
						sm={{ offset: 0, span: 6 }}
					>
						{" "}
						<img
							style={{ objectFit: "contain", height: "100%" }}
							className="imageGallery"
							src="./Images/2021_4.jpg"
							alt="2021 (4)"
						/>
					</Col>
				</Row>
				<Row className="d-flex flex-row justify-content-center align-items-center text-center"></Row>
			</Container>
			<Container fluid>
				<Row className="d-flex flex-column justify-content-center align-items-center">
					<Col
						style={{ marginTop: "55px" }}
						className="d-flex flex-column justify-content-center align-items-center text-center"
						xs={{ offset: 0, span: 10 }}
					>
						<h3 style={{ marginBottom: "0" }}>Our Guarantee</h3>
						<hr style={{ width: "250px", marginTop: "0" }} />
					</Col>
				</Row>
				<Row className="d-flex flex-row justify-content-center">
					<Col
						xs={{ offset: 0, span: 12 }}
						md={{ offset: 0, span: 5 }}
						className="d-flex flex-column justify-content-center text-center"
					>
						<h5>Communication</h5>
						<p>
							Regular on-site evaluations and liaison by management are
							mandatory to ensure that the client is aware of the scheduled job
							and satisfied with the work. Regular meetings will be conducted
							with the Property Manager and Strata Council Members to ensure
							clear communication and understanding.
						</p>
					</Col>

					<Col
						xs={{ offset: 0, span: 12 }}
						md={{ offset: 0, span: 5 }}
						className="d-flex flex-column justify-content-center text-center"
						style={{ height: "100%" }}
					>
						<h5>Go Green</h5>{" "}
						<p>
							At Mayflower Group we use environmentally friendly cleaning
							products and equipment. Furthermore, all our employees are Green
							Certified and have completed the WHMIS course. We ensure that safe
							chemical handling and safe machine operation training stay up to
							date for all employees.
						</p>
					</Col>
				</Row>
				<Row className="d-flex flex-row justify-content-center">
					<Col
						xs={{ offset: 0, span: 12 }}
						md={{ offset: 0, span: 5 }}
						className="d-flex flex-column text-center"
					>
						<h5>Workforce</h5>{" "}
						<p>
							All our employees are professionally trained and are committed to
							providing the best possible service. Each employee passes security
							checks and personal reference checks. Our system of evaluation and
							performance ensures that our workforce is the most efficient and
							meticulous caretakers and cleaners in this industry.
						</p>
					</Col>
					<Col
						xs={{ offset: 0, span: 12 }}
						md={{ offset: 0, span: 5 }}
						className="d-flex flex-column text-center"
					>
						<h5>Insurance</h5>{" "}
						<p>
							At Mayflower Group we have commercial general liability insurance
							through Economical Insurance and Wawanesa Insurance. Furthermore,
							all our employees are bonded and protected by Work Safe BC.
						</p>
					</Col>
				</Row>
				<Row className="d-flex flex-column justify-content-center align-items-center text-center">
					<Col
						className="d-flex flex-column justify-content-center align-items-center text-center"
						xs={{ offset: 0, span: 10 }}
					>
						<h3 style={{ marginBottom: "0" }}>Past Work</h3>
						<hr style={{ width: "250px", marginTop: "0" }} />
					</Col>
				</Row>
				<Row>
					<Col xs={{ offset: 0, span: 12 }} sm={{ offset: 1, span: 10 }}>
						<Carousel
							nextLabel={null}
							prevLabel={null}
							indicators={true}
						>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/1.jpg" alt="First slide" />
								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" style={{objectFit: "contain"}} src="./Images/2.jpg" alt="Second slide" />

								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/3.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/4.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/5.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" style={{objectFit: "contain"}} src="./Images/6.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Strata Building Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/8.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Showroom Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/9.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Showroom Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/10.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Construction Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" style={{objectFit: "contain"}} src="./Images/11.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Construction Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" style={{objectFit: "contain"}} src="./Images/12.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Construction Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/13.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Commercial Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
							<Carousel.Item>
								<img className="d-block w-100" src="./Images/14.jpg" alt="Third slide" />

								<Carousel.Caption>
									<h5>Commercial Cleaning</h5>
								</Carousel.Caption>
							</Carousel.Item>
						</Carousel>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default About;
