import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Styles/Contact.css";

const Contact = ({ isMobile }) => {
	return (
		<div className="full-width-wrapper-white">
			<Container fluid id="contactUs">
				<Row>
					<Col xs={{ offset: 1, span: 10 }} style={{ textAlign: "center" }}>
						<h3>Have a question? Contact Us</h3>
						<h5>
							We provide building maintenance and cleaning services that exceed
							expectations at an affordable rate! Contact us for a quote.{" "}
						</h5>
					</Col>
				</Row>
				<Row className="d-flex flex-row justify-content-center align-items-center">
					<Col
						xs={{ span: 12 }}
						className="d-flex flex-row justify-content-center align-items-center"
						style={{ paddingBottom: "25px" }}
					>
						<svg
							width="50"
							height="50"
							viewBox="0 0 50 50"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0)">
								<path
									d="M2.46661 21.4159V45.3348C2.4537 47.8939 4.51438 49.9807 7.07345 50H42.9267C45.4857 49.9807 47.5465 47.8938 47.5335 45.3348V21.4159C47.5305 20.2511 47.0431 19.14 46.1882 18.3489L43.3606 15.7425V6.60226C43.3606 5.21954 42.2396 4.09854 40.8569 4.09854H30.7428L27.2626 0.882927C25.9845 -0.294309 24.0173 -0.294309 22.7392 0.882927L19.2574 4.09854H9.14322C7.76051 4.09854 6.63951 5.21954 6.63951 6.60226V15.7425L3.81031 18.3497C2.9563 19.1411 2.46964 20.2517 2.46661 21.4159ZM19.7038 33.5823L4.58978 46.918C4.29442 46.4426 4.13726 45.8944 4.13579 45.3348V21.4735L19.7038 33.5823ZM7.07345 48.3309C6.64508 48.329 6.22258 48.2307 5.83744 48.043L21.0442 34.6247L23.4553 36.5008C24.3594 37.2188 25.6391 37.2188 26.5432 36.5008L28.9635 34.6214L44.1627 48.043C43.7775 48.2307 43.355 48.329 42.9267 48.3309H7.07345ZM45.8643 21.4735V45.3349C45.8634 45.8953 45.7061 46.4444 45.4103 46.9205L30.2962 33.5823L45.8643 21.4735ZM45.0548 19.5749C45.1321 19.6519 45.2044 19.7339 45.271 19.8203L43.3606 21.3058V18.0126L45.0548 19.5749ZM23.8734 2.10564C24.51 1.51893 25.4901 1.51893 26.1267 2.10564L28.2833 4.09864H21.7169L23.8734 2.10564ZM9.14322 5.76772H40.857C41.3179 5.76772 41.6915 6.14142 41.6915 6.60226V22.6043L25.5091 35.1906C25.2093 35.4293 24.7838 35.4277 24.4859 35.1864L8.30859 22.6044V6.60226C8.30859 6.14142 8.68228 5.76772 9.14322 5.76772ZM6.63951 18.0126V21.3058L4.72915 19.8203C4.79526 19.7342 4.86686 19.6525 4.94363 19.5758L6.63951 18.0126Z"
									fill="#224D05"
								/>
								<path
									d="M25.0001 29.1357C27.1448 29.1433 29.2342 28.4562 30.9556 27.1769C31.3257 26.9022 31.403 26.3794 31.1283 26.0094C30.8536 25.6393 30.3308 25.5619 29.9608 25.8367C28.5269 26.9019 26.7863 27.4738 25.0001 27.4666C20.3909 27.4666 16.6544 23.7301 16.6544 19.1209C16.6544 14.5117 20.3909 10.7752 25.0001 10.7752C29.6093 10.7752 33.3458 14.5117 33.3458 19.1209V20.7901C33.3458 21.712 32.5985 22.4593 31.6766 22.4593C30.7547 22.4593 30.0074 21.712 30.0074 20.7901V14.948C30.0074 14.4871 29.6338 14.1135 29.1729 14.1135C28.7119 14.1135 28.3383 14.4872 28.3383 14.948V15.3987C26.8264 14.0389 24.6422 13.7285 22.8113 14.6132C20.9803 15.4979 19.8662 17.402 19.992 19.4315C20.1177 21.4611 21.4583 23.2131 23.3845 23.865C25.3107 24.5168 27.4397 23.9392 28.7723 22.4032C29.5044 23.7367 31.0478 24.4037 32.5206 24.023C33.9935 23.6424 35.0205 22.3112 35.0148 20.79V19.1208C35.0148 13.5898 30.531 9.10596 25 9.10596C19.4689 9.10596 14.9851 13.5898 14.9851 19.1208C14.9851 24.6519 19.469 29.1357 25.0001 29.1357ZM25.0001 22.4592C23.1564 22.4592 21.6618 20.9646 21.6618 19.1209C21.6618 17.2773 23.1564 15.7827 25.0001 15.7827C26.8437 15.7827 28.3383 17.2773 28.3383 19.1209C28.3383 20.9646 26.8437 22.4592 25.0001 22.4592Z"
									fill="#224D05"
								/>
							</g>
						</svg>
						<p style={{ marginLeft: "20px", marginBottom: "initial" }}>
							simon@mayflowermgt.com
						</p>
					</Col>
					<Col
						xs={{ span: 12 }}
						className="d-flex flex-row justify-content-center align-items-center"
						style={{ width: "fit-content", paddingBottom: "25px" }}
					>
						<svg
							width="20"
							height="50"
							viewBox="0 0 20 50"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M16.6667 0H10C3.55166 0 0 8.87832 0 25C0 41.1217 3.55166 50 10 50H16.6667C18.5076 50 20 48.5076 20 46.6667V36.6667C20 34.8258 18.5076 33.3334 16.6667 33.3334H11.4375C11.022 33.3381 10.668 33.0326 10.6116 32.6209C10.2476 30.096 10.0433 27.5507 10 25.0001C10.0433 22.4495 10.2476 19.9041 10.6116 17.3793C10.6679 16.9676 11.0219 16.6621 11.4375 16.6668H16.6667C18.5076 16.6668 20 15.1744 20 13.3335V3.3335C20 1.49238 18.5076 0 16.6667 0V0ZM18.3333 36.6667V46.6667C18.3333 47.5872 17.5871 48.3334 16.6666 48.3334H15V35H16.6667C17.5871 35 18.3333 35.7462 18.3333 36.6667ZM8.96162 17.1517C8.58633 19.7519 8.37646 22.3732 8.3333 25C8.37646 27.6268 8.58633 30.2481 8.96162 32.8483C9.1376 34.0812 10.1922 34.9976 11.4375 35H13.3333V48.3333H10C4.7042 48.3333 1.6667 39.8283 1.6667 25C1.6667 10.1717 4.7042 1.6667 10 1.6667H13.3333V15H11.4375C10.1922 15.0024 9.1377 15.9188 8.96162 17.1517ZM18.3333 13.3333C18.3333 14.2538 17.5871 15 16.6666 15H15V1.6667H16.6667C17.5872 1.6667 18.3334 2.41289 18.3334 3.3334V13.3333H18.3333Z"
								fill="#224D05"
							/>
						</svg>
						<p style={{ marginLeft: "20px", marginBottom: "initial" }}>
							(604) 729 3903
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Contact;
