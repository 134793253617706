import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "../Styles/NavigationA.css";

const NavigationA = () => {
	return (
		<div
			className="full-width-wrapper-white"
			style={{ paddingBottom: "0", paddingTop: "0" }}
		>
			<Navbar
				collapseOnSelect
				expand="md"
				bg="light"
				variant="light"
				style={{ paddingBottom: "0", paddingTop: "0", paddingLeft: "0" }}
			>
				<Navbar.Brand
					href="/"
					style={{ paddingBottom: "0", paddingTop: "0" }}
				>
					{" "}
					<img
						src="Images/Logo.png"
						width="75"
						height="59"
						className="d-inline-block align-top"
						alt="Mayflower Group logo"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="mr-auto">
						<Nav.Link href="/" style={{ color: "black" }}>
							Home
						</Nav.Link>
						<Nav.Link href="/about" style={{ color: "black" }}>
							About Us
						</Nav.Link>
						<Nav.Link href="/mission" style={{ color: "black" }}>
							Our Mission
						</Nav.Link>
						<Nav.Link href="/services" style={{ color: "black" }}>
							Services
						</Nav.Link>
						<Nav.Link href="/#contactUs" style={{ color: "black" }}>
							Contact Us
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default NavigationA;
