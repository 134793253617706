import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../Styles/BodyImageA.css";

const BodyImageA = ({ isMobile }) => {
	return (
		<div className="full-width-wrapper-white">
			<Container className="BodyImageA">
				<Row>
					{isMobile ? (
						<>

							<Col xs={{ offset: 1, span: 10 }} sm={{ offset: 1, span: 5 }} style={{marginBottom: "25px"}}>
								<img src="../Images/landing_1.jpg" alt="BIA"></img>
							</Col>
							<Col
								xs={{ offset: 1, span: 10 }}
								sm={{ offset: 1, span: 5 }}
								className="d-flex flex-column justify-content-center"
							>
								<h5>Professional Building Services Since 1996</h5>
								<p>
									At Mayflower Building Maintenance Ltd. and Mayflower Building
									Services Ltd, we have been providing building maintenance and
									cleaning services to the Greater Vancouver area for over 25
									years. We are currently serving over 2000 suites of
									residential Strata Properties.
								</p>
								<div>
									<Button variant="primary" href="/about">
										About Us
									</Button>
								</div>
							</Col>
						</>
					) : (
						<>
							{" "}
							<Col
								xs={{ offset: 1, span: 10 }}
								sm={{ offset: 1, span: 5 }}
								className="d-flex flex-column justify-content-center"
							>
								<h5>Professional Building Services Since 1996</h5>
								<p>
									At Mayflower Building Maintenance Ltd. and Mayflower Building
									Services Ltd, we have been providing building maintenance and
									cleaning services to the Greater Vancouver area for over 25
									years. We are currently serving over 2000 suites of
									residential Strata Properties.
								</p>
								<div>
									<Button variant="primary" href="/about">
										About Us
									</Button>
								</div>
							</Col>
							<Col xs={{ offset: 1, span: 10 }} sm={{ offset: 1, span: 5 }}>
								<img src="../Images/landing_1.jpg" alt="BIA"></img>
							</Col>{" "}
						</>
					)}
				</Row>
			</Container>
		</div>
	);
};

export default BodyImageA;
