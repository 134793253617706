import React from "react";
import { Jumbotron, Button, Container, Row, Col } from "react-bootstrap";
import "../Styles/HeroA.css";
const HeroA = ({ isMobile }) => {
	return (
		<> 
			<Jumbotron style={{ padding: "0", paddingTop: "0" }}>
				<div id="image-tint">
					<Container fluid>
						<Row>
							<Col
								xs={{ offset: 0, span: 12 }}
								sm={{ offset: 1, span: 12 }}
								lg={{ offset: 1, span: 9 }}
							>
								<h1>Mayflower Group</h1>
								<Container
									style={{ paddingLeft: 0, marginRight: 0, marginLeft: 0 }}
								>
									<h4>
										Professional building maintenance and cleaning services for
										residential strata properties
									</h4>
									<hr />
								</Container>
								<h6>Vancouver | Lower Mainland</h6>
								<h6>604.729.3903 | simon@mayflowermgt.com</h6>
								<p>
									<Button href="/mission">Our Mission</Button>
								</p>
							</Col>
						</Row>
					</Container>
				</div>
			</Jumbotron>
		</>
	);
};

export default HeroA;
