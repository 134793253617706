import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CenteredFeature = () => {
	return (
		<div className="full-width-wrapper-white">
			<Container fluid id="contactUs">
				<Row>
					<Col xs={{ offset: 1, span: 10 }} style={{ textAlign: "center" }}>
						<h5 style={{fontWeight: "700"}}>Some Words About us</h5>
						<p>
							Mayflower Building Services Ltd is a British Columbia owned and
							operated company. Our company core values are ‘’ Care, Respect,
							Responsibility’’. We care for and respect our clients’ needs. Our
							staff takes responsibility to protect the environment by using
							environmental friendly cleaning products.
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CenteredFeature;
