import React from "react";
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import "../Styles/Mission.css";
import CenteredFeature from "../Components/CenteredFeature";

const Mission = ({ isMobile }) => {
	return (
		<div class="mission-page">
			<Jumbotron style={{ padding: "0", paddingTop: "0" }}>
				<div id="image-tint-green">
					<Container fluid>
						<Row>
							<Col
								xs={{ offset: 0, span: 12 }}
								sm={{ offset: 1, span: 10 }}
								lg={{ offset: 1, span: 9 }}
							>
								<h1>
									Our<br></br>Vision
								</h1>
								<p id="vision-statement">
									At Mayflower Group we strive to work with all walks of life to
									be environment friendly. <br></br>
									<br></br>
									We GROW in pace with our clients' needs, step up to our
									PROMISE and PRIDE on our PROFESSIONALISM!
								</p>
							</Col>
						</Row>
					</Container>
				</div>
			</Jumbotron>
			<div className="full-width-wrapper-white">
				<Container className="BodyImageA">
					<Row>
						{isMobile ? (
							<>
								<Col
									xs={{ offset: 1, span: 10 }}
									sm={{ offset: 1, span: 5 }}
									style={{ marginBottom: "25px" }}
								>
									<img
										src="../Images/mayflower-group-workers.jpg"
										alt="workers"
									></img>
								</Col>
								<Col
									xs={{ offset: 1, span: 10 }}
									sm={{ offset: 1, span: 5 }}
									className="d-flex flex-column justify-content-center"
								>
									<h5>Our Mission</h5>
									<p>
										Our mission is to provide our valued clients with
										exceptional cleaning services while protecting our
										environment with Go Green environmentally safe cleaning
										products. We believe that listening and providing effective
										solutions for our clients fosters long term relationships.
										We maintain our reputation through remarkable work and
										respecting our valued clients and staff.
									</p>
								</Col>
							</>
						) : (
							<>
								{" "}
								<Col
									xs={{ offset: 1, span: 10 }}
									sm={{ offset: 1, span: 5 }}
									className="d-flex flex-column justify-content-center"
								>
									<h5>Our Mission</h5>
									<p>
										Our mission is to provide our valued clients with
										exceptional cleaning services while protecting our
										environment with Go Green environmentally safe cleaning
										products. We believe that listening and providing effective
										solutions for our clients fosters long term relationships.
										We maintain our reputation through remarkable work and
										respecting our valued clients and staff.
									</p>
								</Col>
								<Col xs={{ offset: 1, span: 10 }} sm={{ offset: 1, span: 5 }}>
									<img
										src="../Images/mayflower-group-workers.jpg"
										alt="workers"
									></img>
								</Col>{" "}
							</>
						)}
					</Row>
				</Container>
				<CenteredFeature></CenteredFeature>
			</div>
		</div>
	);
};

export default Mission;
