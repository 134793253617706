import "./Styles/App.scss";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavA from "./Components/NavigationA";
import FooterA from "./Components/FooterA";
import Home from "./Views/Home";
import About from "./Views/About";
import Services from "./Views/Services";
import Mission from "./Views/Mission";

function App() {
	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	let isMobile = width <= 992;

	return (
		<Router>
			<div className="App">
				<NavA isMobile={isMobile}></NavA>
				<Switch>
					<Route path="/mission">
						<Mission isMobile={isMobile}/>
					</Route>
					<Route path="/about">
						<About isMobile={isMobile}/>
					</Route>
					<Route path="/services">
						<Services />
					</Route>
					<Route path="/">
						<Home isMobile={isMobile}></Home>
					</Route>
				</Switch>
				<FooterA isMobile={isMobile}></FooterA>
			</div>
		</Router>
	);
}

export default App;
