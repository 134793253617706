import React from "react";
import { Button } from "react-bootstrap";

const BodyFeatureA = ({title, body, buttonText, linkHref, isMobile}) => {
	return (
		<>
			<h4>{title}</h4>
			<p>{body}</p>
            <Button variant="outline-primary" href={linkHref}>{buttonText}</Button>
		</>
	);
};

export default BodyFeatureA;
