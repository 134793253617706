import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "../Styles/FooterA.css";

const FooterA = ({ isMobile }) => {
	return (
		<div className="FooterA">
			<div className="center-wrapper">
				<Nav className="justify-content-center" activeKey="/home">
					{isMobile ? (
						<div className="d-flex flex-column align-items-center">
							{" "}
							<Nav.Item>
								<Navbar.Brand id="FooterBrand" href="#home">
									<img
										src="Images/Logo.png"
										width="112.5"
										height="88.5"
										alt="Mayflower Group logo"
									/>
								</Navbar.Brand>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/">Home</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/about">About Us</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/services">Services</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="#contactUs">
									Contact Us
								</Nav.Link>
							</Nav.Item>
						</div>
					) : (
						<>
							{" "}
							<Nav.Item>
								<Nav.Link href="/">Home</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/about">About Us</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Navbar.Brand id="FooterBrand" href="/">
									<img
										src="Images/Logo.png"
										width="112.5"
										height="88.5"
										alt="Mayflower Group logo"
									/>
								</Navbar.Brand>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/services">Services</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/#contactUs">
									Contact Us
								</Nav.Link>
							</Nav.Item>{" "}
						</>
					)}
				</Nav>
				<hr />
				<p style={{ color: "white", textAlign: "center" }}>
					Mayflower Group © Copyright 2021. <br></br> Website by{" "}
					<a style={{ color: "white" }} href="https://lindenkwok.ca/">
						Linden Kwok
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export default FooterA;
