import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../Styles/Services.css";

const Services = () => {
	return (
		<>
			<div className="servicesPage">
				<Container fluid>
					<Row className="d-flex flex-column justify-content-center">
						<Col
							className="d-flex flex-column justify-content-center align-items-center text-center"
							xs={{ offset: 1, span: 10 }}
						>
							<h3 style={{ marginBottom: "0" }}>Services</h3>
							<hr style={{ width: "200px", marginTop: "0" }} />
						</Col>
					</Row>
					<Row>
						<Col xs={{ offset: 1, span: 10 }} lg={{ offset: 1, span: 5 }}>
							<h4 className="text-center">Residential Strata Buildings</h4>
							<p className="text-center">
								At Mayflower Group we offer regular maintenance and cleaning
								services for residential strata buildings.
							</p>
							<ul>
								<li>
									Regular maintenance and cleaning services for common areas
								</li>
								<li>Regular floor care for building common areas</li>
								<li>Regular carpet cleaning for building common areas</li>
								<li>Regular and relief caretaker services</li>
								<li>
									Tailor-made cleaning services for Developer Presentation
									Centers
								</li>
								<li>
									Other building services as specified by Strata and Property
									Management
								</li>
							</ul>
						</Col>
						<Col xs={{ offset: 1, span: 10 }} lg={{ offset: 0, span: 5 }}>
							<h4 className="text-center">Commercial Cleaning Services</h4>
							<p className="text-center">
								With decades of proven experience cleaning a variety of
								commercial facilities, we ensure all your facilities are
								clean.
							</p>
							<ul>
								<li>Commercial Buildings and Factories</li>
								<li>Restaurants</li>
								<li>Recreational Centres</li>
								<li>Shopping Malls</li>
								<li>Medical Clinics</li>
								<li>Developer Presentation Centres</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<Col xs={{ offset: 1, span: 10 }}>
							<h4 className="text-center">
								24/7/365 Emergency Maintenance and Cleaning Services
							</h4>
							<p className="text-center">
								Our services are available 24 hours a day, 7 days a week, and
								365 days a year. We have emergency response teams and a reserve
								pool to support and attend to any of our client’s need.
							</p>
						</Col>
					</Row>
				</Container>
				<Container className="full-width-wrapper-grey">
					<Row className="d-flex flex-column justify-content-center text-center">
						<Col xs={{ offset: 1, span: 10 }}>
							<h5>
								Have questions? Contact us, we
								would love to hear from you.
							</h5>
							<Button href="/#contactUs" style={{ width: "fit-content" }} variant="primary">
								Contact Us
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Services;
