import React from "react";
import HeroA from "../Components/HeroA";
import BodyA from "../Components/BodyA";
import BodyImageA from "../Components/BodyImageA";
import Contact from "../Components/Contact";


const Home = ({isMobile}) => {
	return (
		<>
			<HeroA isMobile={isMobile}></HeroA>
			<BodyImageA isMobile={isMobile}></BodyImageA>
			<BodyA isMobile={isMobile}></BodyA>
			<Contact isMobile={isMobile}></Contact>
		</>
	);
};

export default Home;
